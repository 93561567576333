// Packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// Components
import SetUpEnvironment from './SetUpEnvironment';
import SetUpContexts from './SetUpContexts';
import ContextStores from './ContextStores';
// UI
import DisplayIsLoadingPageBlocker from '../ui/DisplayIsLoadingPageBlocker';
import DisplayIsLoadingInputBlocker from '../ui/DisplayIsLoadingInputBlocker';
import DisplayPopup from './DisplayPopup';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    page: PropTypes.string.isRequired,
};

function SetUpRoute({ children, page }) {
    const { company } = useParams();

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/main.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/main.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/type-styles.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/type-styles.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/spacing.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/spacing.less`);
        });
    }, [company]);

    return (
        <ContextStores>
            <DisplayPopup />
            <DisplayIsLoadingInputBlocker />
            <DisplayIsLoadingPageBlocker>
                {children}
            </DisplayIsLoadingPageBlocker>
            <SetUpEnvironment />
            <SetUpContexts page={page} />
        </ContextStores>
    );
}

SetUpRoute.propTypes = propTypes;

export default SetUpRoute;
