const classNames = {
    filled: 'filled-button',
    outlined: 'outlined-button',
    next: 'filled-button next-button',
    loneNext: 'filled-button lone-next-button',
    previous: 'outlined-button previous-button',
    nextNoBottomMargin: 'filled-button next-button-no-bottom-margin',
    disclosuresPrevious: 'outlined-button disclosures-previous-button',
    disclosuresNext: 'filled-button disclosures-next-button',
    label: {
        div: 'label-button-div',
        label: 'label-button-label',
        button: 'label-button-button outlined-button'
    },
};

export default classNames;
