// Packages
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Pages
import Form from '../pages/Form';
import EditForm from '../pages/EditForm';
import Disclosures from '../pages/Disclosures';
import Error from '../pages/Error';
// Components
import SetUpRoute from './SetUpRoute';
import ThankYou from '../pages/ThankYou';

function Routers() {
    return (
        <Router>
            <Routes>
                <Route
                    path="/form/:company/:userId"
                    element={
                        <SetUpRoute page="form">
                            <Form />
                        </SetUpRoute>
                    }
                />
                <Route
                    path="/:environment/form/:company/:userId"
                    element={
                        <SetUpRoute page="form">
                            <Form />
                        </SetUpRoute>
                    }
                />
                <Route
                    path="/edit/:company/:pageNumber/:userId"
                    element={
                        <SetUpRoute page="edit">
                            <EditForm />
                        </SetUpRoute>
                    }
                />
                <Route
                    path="/:environment/edit/:company/:pageNumber/:userId"
                    element={
                        <SetUpRoute page="edit">
                            <EditForm />
                        </SetUpRoute>
                    }
                />
                <Route
                    path="/disclosures/:company/:userId"
                    element={
                        <SetUpRoute page="disclosures">
                            <Disclosures />
                        </SetUpRoute>
                    }
                />
                <Route
                    path="/:environment/disclosures/:company/:userId"
                    element={
                        <SetUpRoute page="disclosures">
                            <Disclosures />
                        </SetUpRoute>
                    }
                />
                <Route
                    path="/error/:company/:userId"
                    element={
                        <Error />
                    }
                />
                <Route
                    path="/thankyou/:company/:userId"
                    element={
                        <ThankYou />
                    }
                />
            </Routes>
        </Router>
    );
}

export default Routers;
