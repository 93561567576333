// Packages
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// UI
import Header from '../ui/Header';
import Page from '../ui/Page';
import Container from '../ui/Container';
import Font from '../ui/Font';
import FooterFCCSSL from '../ui/FooterFCCSSL';
import Main from '../ui/Main';
import Step from '../ui/Step';
import HeadingText from '../ui/HeadingText';
import SubHeadingText from '../ui/SubHeadingText';


function ThankYou() {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackMode: "eager" */ `../companies/${company}/styles/pages/ThankYou/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/pages/ThankYou/index.less`);
        });
        import(/* webpackMode: "eager" */ `../companies/${company}/styles/pages/ThankYou/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/pages/ThankYou/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/pages/ThankYou.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/pages/ThankYou.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/main.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/main.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/type-styles.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/type-styles.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/spacing.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/spacing.less`);
        });
    }, [company]);

    return (
        <Page className={classNames.page}>
            <Font />
            <Container className={classNames.container}>
                <Header />
                <Main>
                    <Step>
                        <div>
                            <HeadingText>Thank you</HeadingText>
                            <SubHeadingText>Please go back to messenger.</SubHeadingText>
                            <center>
                                <svg
                                    id="Capa_1"
                                    className={classNames.checkMark}
                                    data-name="Capa 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 513 497.33"
                                >
                                    <path
                                        className="cls-1"
                                        id="path"
                                        d="M474.05,173.81a8,8,0,0,0-5.13,10.09,232.84,232.84,0,1,1-50.49-85.74,8,8,0,1,0,11.73-10.88,247.32,247.32,0,1,0,54,91.66A8,8,0,0,0,474.05,173.81Z"
                                        transform="translate(0.5 -7.33)"
                                    />
                                    <path
                                        className="cls-1"
                                        id="path"
                                        d="M505,83.26a24,24,0,0,0-34,0l-7.16,7.16a8,8,0,1,0,11.31,11.31l7.17-7.16a8,8,0,1,1,11.32,11.33L217.8,381.75a8,8,0,0,1-11.32,0L98.75,274a8,8,0,1,1,11.32-11.33l96.42,96.41a8,8,0,0,0,11.31,0L452.65,124.25a8,8,0,1,0-11.31-11.31L212.14,342.13l-90.75-90.75a24,24,0,1,0-34,33.95L195.16,393.06a24,24,0,0,0,34,0L505,117.21a24,24,0,0,0,0-33.95Z"
                                        transform="translate(0.5 -7.33)"
                                    />
                                </svg>
                            </center>
                        </div>
                    </Step>
                </Main>
                <FooterFCCSSL />
            </Container>
        </Page>
    );
}

export default ThankYou;