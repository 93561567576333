const ValidationReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FIELDS':
            return { ...state, fields: action.payload };
        case 'RELOAD_VALIDATION':
            return { ...state, reloadValidation: state.reloadValidation + 1 };
        case 'RESET_RELOAD_VALIDATION':
            return { ...state, reloadValidation: 0 };
        case 'SET_WAITING_TRUE':
            return { ...state, waitingForValidationCompletion: {  ...state.waitingForValidationCompletion, [action.payload]: true } };
        case 'SET_WAITING_FALSE':
            return { ...state, waitingForValidationCompletion: {  ...state.waitingForValidationCompletion, [action.payload]: false } };
        case 'SET_ACCEPTEDDISCLOSURES':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    acceptedDisclosures: action.payload,
                },
            };
        case 'SET_ACCEPTEDDISCLOSURES_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    acceptedDisclosures: { ...state.fields.acceptedDisclosures, step: action.payload },
                },
            };
        case 'SET_ADDRESSOBJECT':
            return { ...state, fields: { ...state.fields, addressObject: action.payload } };
        case 'SET_ADDRESSOBJECT_ISVALID':
            return { ...state, fields: { ...state.fields, addressObject: { ...state.fields.addressObject, isValid: action.payload } } };
        case 'SET_ADDRESSOBJECT_STEP':
            return {
                ...state,
                fields: { ...state.fields, addressObject: { ...state.fields.addressObject, step: action.payload } },
            };
        case 'SET_SHIPPINGADDRESSOBJECT':
            return { ...state, fields: { ...state.fields, shippingAddressObject: action.payload } };
        case 'SET_SHIPPINGADDRESSOBJECT_ISVALID':
            return { ...state, fields: { ...state.fields, shippingAddressObject: { ...state.fields.shippingAddressObject, isValid: action.payload } } };
        case 'SET_SHIPPINGADDRESSOBJECT_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    shippingAddressObject: { ...state.fields.shippingAddressObject, step: action.payload },
                },
            };
        case 'SET_PROGRAMS':
            return { ...state, fields: { ...state.fields, programs: action.payload } };
        case 'SET_PROGRAMS_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    programs: { ...state.fields.programs, step: action.payload },
                },
            };
        case 'SET_PROGRAMS_ISVALID':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    programs: { ...state.fields.programs, isValid: action.payload },
                },
            };
        case 'SET_FIRSTNAME':
            return { ...state, fields: { ...state.fields, firstName: action.payload } };
        case 'SET_FIRSTNAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    firstName: { ...state.fields.firstName, step: action.payload },
                },
            };
        case 'SET_MIDDLENAME':
            return { ...state, fields: { ...state.fields, middleName: action.payload } };
        case 'SET_MIDDLENAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    middleName: { ...state.fields.middleName, step: action.payload },
                },
            };
        case 'SET_LASTNAME':
            return { ...state, fields: { ...state.fields, lastName: action.payload } };
        case 'SET_LASTNAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    lastName: { ...state.fields.lastName, step: action.payload },
                },
            };
        case 'SET_SECONDLASTNAME':
            return { ...state, fields: { ...state.fields, secondLastName: action.payload } };
        case 'SET_SECONDLASTNAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    secondLastName: { ...state.fields.secondLastName, step: action.payload },
                },
            };
        case 'SET_PHONENUMBER':
            return { ...state, fields: { ...state.fields, phoneNumber: action.payload } };
        case 'SET_PHONENUMBER_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    phoneNumber: { ...state.fields.phoneNumber, step: action.payload },
                },
            };
        case 'SET_DOB':
            return { ...state, fields: { ...state.fields, dob: action.payload } };
        case 'SET_DOB_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dob: { ...state.fields.dob, step: action.payload },
                },
            };
        case 'SET_SSN':
            return { ...state, fields: { ...state.fields, ssn: action.payload } };
        case 'SET_SSN_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ssn: { ...state.fields.ssn, step: action.payload },
                },
            };
        case 'SET_CLICKEDACTIVATEDEMO':
            return { ...state, fields: { ...state.fields, clickedActivateDemo: action.payload } };
        case 'SET_CLICKEDACTIVATEDEMO_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    clickedActivateDemo: { ...state.fields.clickedActivateDemo, step: action.payload },
                },
            };
        case 'SET_USERMOBILECONSENT':
            return { ...state, fields: { ...state.fields, userMobileConsent: action.payload } };
        case 'SET_USERMOBILECONSENT_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    userMobileConsent: { ...state.fields.userMobileConsent, step: action.payload },
                },
            };
        default:
            return state;
    }
};

export default ValidationReducer;
