// Packages
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Contexts
import { StepsContext } from '../contexts/Steps';

// Props of the Component
const propTypes = {
};

const ProgressStepper = ({ }) => {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [steps, stepsDispatch] = useContext(StepsContext);
    const [subtractFromLength, setSubtractFromLength] = useState(0)
    const [subtractFromCurrentStep, setSubtractFromCurrentStep] = useState(0)
    const [currentStep, setCurrentStep] = useState(
        steps.steps[steps.currentStep].ProgressStepperIndex || steps.steps[steps.currentStep].ProgressStepperIndex === 0 ? 
            steps.steps[steps.currentStep].ProgressStepperIndex : 
            steps.currentStep - subtractFromCurrentStep
    );
    const [totalSteps, setTotalSteps] = useState(steps.steps.length - subtractFromLength);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ProgressStepper/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ProgressStepper/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/ProgressStepper.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/ProgressStepper.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ProgressStepper/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ProgressStepper/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);
    
    useEffect(() => {
        if (steps?.metaData) {
            if (subtractFromLength !== steps.metaData.subtractFromStepsLength && subtractFromCurrentStep !== steps.metaData.subtractFromCurrentStep) {
                setSubtractFromLength((steps.metaData.subtractFromStepsLength ? steps.metaData.subtractFromStepsLength : 0 ))
                setSubtractFromCurrentStep((steps.metaData.subtractFromCurrentStep ? steps.metaData.subtractFromCurrentStep : 0))
            }
        }
    }, [steps])
    useEffect(() => {
        setCurrentStep(
            steps.steps[steps.currentStep].ProgressStepperIndex || steps.steps[steps.currentStep].ProgressStepperIndex === 0 ? 
                steps.steps[steps.currentStep].ProgressStepperIndex : 
                steps.currentStep - subtractFromCurrentStep
        );
        setTotalSteps(steps.steps.length - subtractFromLength);
    }, [subtractFromLength, subtractFromCurrentStep, steps.currentStep, steps.steps.length])

    const stepsRange = Array.from(Array(totalSteps).keys())

    return (
        <>
            <div className={classNames.ProgressStepperContainer}>
                {stepsRange.map((i, idx) => {
                    if (idx === currentStep && idx === 0) {
                        return <Fragment key={i}>
                            <div className={`${classNames.circle} ${classNames.currentCircle}`}></div>
                        </Fragment>
                    }
                    if (idx === currentStep && idx > 0) {
                        return <Fragment key={i}>
                            <div className={`${classNames.bar} ${classNames.filledBar}`}></div>
                            <div className={`${classNames.circle} ${classNames.currentCircle}`}></div>
                        </Fragment>
                    }
                    if (idx < currentStep && idx > 0) {
                        return <Fragment key={i}>
                            <div className={`${classNames.bar} ${classNames.filledBar}`}></div>
                            <div className={`${classNames.circle} ${classNames.filledCircle}`}></div>
                        </Fragment>
                    }
                    if (idx < currentStep && idx === 0) {
                        return <Fragment key={i}>
                            <div className={`${classNames.circle} ${classNames.filledCircle}`}></div>
                        </Fragment>
                    }
                    if (idx > currentStep) {
                        return <Fragment key={i}>
                            <div className={`${classNames.bar} ${classNames.outlinedBar}`}></div>
                            <div className={`${classNames.circle} ${classNames.outlinedCircle}`}></div>
                        </Fragment>
                    }
                })}
            </div>
        </>
    );
};

ProgressStepper.propTypes = propTypes;

export default ProgressStepper;
