const FormDataReducer = (state, action) => {
    switch (action.type) {
        case 'SET':
            return action.payload;
        case 'SET_FORMDATA':
            return {
                ...state,
                address: action.payload.address,
                temporaryAddress: action.payload.temporaryAddress,
                shippingAddress: action.payload.shippingAddress,
            };
        case 'SET_ADDRESS':
            return { ...state, address: { ...state.address, ...action.payload } };
        case 'CLEAR_ADDRESS':
            return { ...state, address: {} };
        case 'SET_ADDRESS_LINE_2':
            return { ...state, address: { ...state.address, addressLine2: action.payload } };
        case 'SET_TEMPORARY_ADDRESS':
            return { ...state, temporaryAddress: action.payload };
        case 'CLEAR_TEMPORARY_ADDRESS':
            return { ...state, temporaryAddress: "Permanent" };
        case 'SET_SHIPPING_ADDRESS':
            return { ...state, shippingAddress: { ...state.shippingAddress, ...action.payload } };
        case 'SET_SHIPPING_ADDRESS_LINE_2':
            return { ...state, shippingAddress: { ...state.shippingAddress, addressLine2: action.payload } };
        case 'SET_SHIPPING_ADDRESS_NULL':
            return { ...state, shippingAddress: null };
        case 'ADD_PROGRAM':
            return { ...state, programs: [action.payload] };
        case 'CLEAR_PROGRAM':
            return { ...state, programs: [] };
        case 'REMOVE_PROGRAM':
            return {
                ...state,
                programs: state.programs.filter((program) => program.program_code !== action.payload.program_code),
            };
        case 'SET_FIRST_NAME':
            return { ...state, firstName: action.payload };
        case 'SET_LAST_NAME':
            return { ...state, lastName: action.payload };
        case 'SET_MIDDLE_NAME':
            return { ...state, middleName: action.payload };
        case 'SET_SECOND_LAST_NAME':
            return { ...state, secondLastName: action.payload };
        case 'SET_SUFFIX':
            return { ...state, suffix: action.payload };
        case 'SET_PHONE_NAME':
            return { ...state, phoneNumber: action.payload };
        case 'SET_DOB':
            return { ...state, dob: action.payload };
        case 'SET_SSN':
            return { ...state, ssn: action.payload };
        case 'SET_ACCEPTED_DISCLOSURES':
            return { ...state, acceptedDisclosures: action.payload };
        case 'SET_CLICKED_ACTIVATE_DEMO':
            return { ...state, clickedActivateDemo: action.payload };
        case 'SET_USER_MOBILE_CONSENT':
            return { ...state, userMobileConsent: action.payload };
        case 'SET_SERVICEABLE_COMPANY':
            return { ...state, serviceableCompany: action.payload };
        case 'SET_PREDICTIVE_SEARCH_ADDRESS_OBJECT':
            return { ...state, predictiveSearchAddressObject: action.payload };
        case 'INCREMENT_FREEFORM_ADDRESS_TIMES_AT_PAGE_1':
            return { ...state, freeformAddressTimesAtPage1: state.freeformAddressTimesAtPage1 + 1 };
        case 'INCREMENT_FREEFORM_ADDRESS_TIMES_AT_PAGE_2':
            return { ...state, freeformAddressTimesAtPage2: state.freeformAddressTimesAtPage2 + 1 };
        case 'INCREMENT_FREEFORM_ADDRESS_TIMES_AT_PAGE_3':
            return { ...state, freeformAddressTimesAtPage3: state.freeformAddressTimesAtPage3 + 1 };
        case 'SET_SERVICEABLE_COMPANIES':
            return { ...state, serviceableCompanies: action.payload };
        default:
            return state;
    }
};

export default FormDataReducer;
