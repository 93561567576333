// Packages
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Components
import Stepper from '../components/Stepper';
// UI
import SuperHeadingText from '../ui/SuperHeadingText';
import SubHeadingText from '../ui/SubHeadingText';
import Container from '../ui/Container';
import Font from '../ui/Font';
import FooterFCCSSL from '../ui/FooterFCCSSL';
import Main from '../ui/Main';
import ConditionalRender from '../components/ConditionalRender';

function Error() {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/pages/Error/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/pages/Error/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/pages/Error/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/pages/Error/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/pages/Error.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/pages/Error.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/main.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/main.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/type-styles.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/type-styles.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/spacing.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/spacing.less`);
        });
    }, [company]);

    return (
        <div className={classNames.page}>
            <Font />
            <SuperHeadingText className={classNames.superHeader}>Oops!</SuperHeadingText>
            <SubHeadingText>It seems a minor hiccup has befallen your application's digital journey. Please head back to Messenger to get your application back on track.</SubHeadingText>
            <ConditionalRender condition={classNames?.image?.src && classNames?.image?.altText}>
                <img className={classNames?.image?.className} src={classNames?.image?.src} alt={classNames?.image?.altText} />
            </ConditionalRender>
        </div>
    );
}

export default Error;
