// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
// Components
import Stepper from '../components/Stepper';
// UI
import Header from '../ui/Header';
import Page from '../ui/Page';
import Container from '../ui/Container';
import Font from '../ui/Font';
import Main from '../ui/Main';
import FooterFCCSSL from '../ui/FooterFCCSSL'
// Contexts
import { FormDataContext } from '../contexts/FormData';
import { EnvironmentContext } from '../contexts/Environment';

function Form() {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const environmentState = useContext(EnvironmentContext)[0];
    const formDataState = useContext(FormDataContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/pages/Disclosures/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/pages/Disclosures/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/pages/Disclosures/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/pages/Disclosures/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <Page className={classNames.page}>
            <Font />
            <Container className={classNames.container}>
                <Header />
                <Main>
                    <Stepper
                        validateOnNext
                        validateOnSubmit
                    />
                </Main>
                <FooterFCCSSL />
            </Container>
        </Page>
    );
}

export default Form;
