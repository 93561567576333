// Helpers
import initialState from './initialState';

const EnvironmentReducer = (state, action) => {
    switch (action.type) {
        case 'SET_INITIAL':
            return initialState;
        case 'SET_BACKEND_URL_TO_GET_DATA':
            return { ...state, backendUrlToGetData: action.payload };
        case 'SET_BACKEND_URL_TO_SET_DATA':
            return { ...state, backendUrlToSetData: action.payload };
        case 'SET_BACKEND_URL_TO_SUBMIT_DATA':
            return { ...state, backendUrlToSubmitData: action.payload };
        case 'SET_DISCLOSURES_BACKEND_URL_TO_GET_DATA':
            return { ...state, disclosuresBackendUrlToGetData: action.payload };
        case 'SET_SMARTY_URL':
            return { ...state, smartyUrl: action.payload };
        case 'SET_VCARE_URL':
            return { ...state, vcareUrl: action.payload };
        case 'SET_MARKETPLACE_SERVICEABILITY_URL':
            return { ...state, marketplaceServiceabilityBackendUrlToCheck: action.payload };
        case 'SET_KORE_SET_URL':
            return { ...state, backendUrlToSetDataForKore: action.payload };
        case 'SET_FRONTIER_PREDICTIVE_SEARCH_URL':
            return { ...state, frontierPredictiveSearchUrl: action.payload };
        case 'SET_FRONTIER_GET_OFFERS_BY_ADDRESS_URL':
            return { ...state, frontierGetOffersByAddressUrl: action.payload };
        default:
            return state;
    }
};

export default EnvironmentReducer;
