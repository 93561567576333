export const backendUrlToGetData = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/get';
        default:
            return 'https://glp8ta3547.execute-api.us-east-1.amazonaws.com/prod/get';
    }
};
export const backendUrlToSetData = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/set';
        default:
            return 'https://glp8ta3547.execute-api.us-east-1.amazonaws.com/prod/set';
    }
};
export const backendUrlToSubmitData = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/set';
        default:
            return 'https://glp8ta3547.execute-api.us-east-1.amazonaws.com/prod/submit';
    }
};
export const disclosuresBackendUrlToGetData = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        default:
            return 'https://txya81kbwe.execute-api.us-east-1.amazonaws.com/prod';
    }
};
export const smartyVerificationUrl = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        case 'test':
            return 'https://96ar7fxe0b.execute-api.us-east-1.amazonaws.com/prod/address_verification';
        default:
            return 'https://96ar7fxe0b.execute-api.us-east-1.amazonaws.com/prod/address_verification';
    }
};
export const vcareApiUrl = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        case 'test':
            return 'https://hk5eio0zbk.execute-api.us-east-1.amazonaws.com/dev/Vcare_API';
        default:
            return 'https://hk5eio0zbk.execute-api.us-east-1.amazonaws.com/live/Vcare_API';
    }
}
export const marketplaceServiceabilityBackendUrlToCheck = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        case 'test':
            return 'https://f6ct3tzkk2iqssha5ppdosuinm0xtlhq.lambda-url.us-east-1.on.aws/';
        default:
            return 'https://f6ct3tzkk2iqssha5ppdosuinm0xtlhq.lambda-url.us-east-1.on.aws/';
    }
}

export const backendUrlToSetDataForKore = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/set';
        case 'localhosttest':
            return 'http://localhost:3000/test/set';
        case 'dev':
            return 'https://afadojxfa5.execute-api.us-east-1.amazonaws.com/prod/set';
        case 'devtest':
            return 'https://afadojxfa5.execute-api.us-east-1.amazonaws.com/prod/test/set';
        case 'testing':
            return 'https://ffsvy5gce5.execute-api.us-east-1.amazonaws.com/prod/set';
        case 'testingtest':
            return 'https://ffsvy5gce5.execute-api.us-east-1.amazonaws.com/prod/test/set';
        case 'prodtest':
            return 'https://ftzc141ic1.execute-api.us-east-1.amazonaws.com/prod/test/set';
        default:
            return 'https://ftzc141ic1.execute-api.us-east-1.amazonaws.com/prod/set';
    }
}

export const baseURLForFrontierBackend = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/prod/';
        case 'localhosttest':
            return 'http://localhost:3000/ctest/';
        case 'devtest':
            return 'https://5001jjkhqb.execute-api.us-east-1.amazonaws.com/prod/ctest/';
        case 'dev':
            return 'https://5001jjkhqb.execute-api.us-east-1.amazonaws.com/prod/prod/';
        case 'testingtest':
            return 'https://6yrd9v3205.execute-api.us-east-1.amazonaws.com/prod/ctest/';
        case 'testing':
            return 'https://6yrd9v3205.execute-api.us-east-1.amazonaws.com/prod/prod/';
        case 'prodtest':
            return 'https://o4tgd15pz6.execute-api.us-east-1.amazonaws.com/prod/ctest/';
        default:
            return 'https://o4tgd15pz6.execute-api.us-east-1.amazonaws.com/prod/prod/';
    }
}

export const urlForFrontierPredictiveSearch = (environment) => {
    return `${baseURLForFrontierBackend(environment)}search`;
}

export const urlForFrontierGetOffersByAddress = (environment) => {
    return `${baseURLForFrontierBackend(environment)}get_offers_by_address`;
}

export const keyForFrontierGetOffersByAddress = (environment) => {
    switch (environment) {
        case 'localhost':
            return '';
        case 'localhosttest':
            return '';
        case 'devtest':
            return process.env.DEV_FRONTIER_API_KEY;
        case 'dev':
            return process.env.DEV_FRONTIER_API_KEY;
        case 'testingtest':
            return process.env.TESTING_FRONTIER_API_KEY;
        case 'testing':
            return process.env.TESTING_FRONTIER_API_KEY;
        case 'prodtest':
            return process.env.FRONTIER_API_KEY;
        default:
            return process.env.FRONTIER_API_KEY;
    }
}
export const keyForFrontierPredictiveSearch = (environment) => {
    switch (environment) {
        case 'localhost':
            return '';
        case 'localhosttest':
            return '';
        case 'devtest':
            return process.env.DEV_FRONTIER_API_KEY;
        case 'dev':
            return process.env.DEV_FRONTIER_API_KEY;
        case 'testingtest':
            return process.env.TESTING_FRONTIER_API_KEY;
        case 'testing':
            return process.env.TESTING_FRONTIER_API_KEY;
        case 'prodtest':
            return process.env.FRONTIER_API_KEY;
        default:
            return process.env.FRONTIER_API_KEY;
    }
}