// Packages
import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
// Helpers
import {
    backendUrlToGetData,
    backendUrlToSetData,
    backendUrlToSubmitData,
    disclosuresBackendUrlToGetData,
    smartyVerificationUrl,
    vcareApiUrl,
    marketplaceServiceabilityBackendUrlToCheck,
    backendUrlToSetDataForKore,
    urlForFrontierPredictiveSearch,
    urlForFrontierGetOffersByAddress,
    keyForFrontierGetOffersByAddress,
    keyForFrontierPredictiveSearch
} from '../helpers/backendURL';
// Contexts
import { EnvironmentContext } from '../contexts/Environment';
import { IsLoadingContext } from '../contexts/IsLoading';

function SetUpEnvironment() {
    const { environment } = useParams();
    const [environmentState, environmentDispatch] = useContext(EnvironmentContext);
    const isLoadingDispatch = useContext(IsLoadingContext)[1];

    useEffect(() => {
        isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpEnvironment" })
    }, [])

    useEffect(() => {
        if (environmentState.backendUrlToGetData && environmentState.backendUrlToSetData) {
            isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpEnvironment" })
        }
    }, [environmentState]);

    useEffect(() => {
        environmentDispatch({ type: 'SET_BACKEND_URL_TO_GET_DATA', payload: backendUrlToGetData(environment) });
        environmentDispatch({ type: 'SET_BACKEND_URL_TO_SET_DATA', payload: backendUrlToSetData(environment) });
        environmentDispatch({ type: 'SET_BACKEND_URL_TO_SUBMIT_DATA', payload: backendUrlToSubmitData(environment) });
        environmentDispatch({
            type: 'SET_DISCLOSURES_BACKEND_URL_TO_GET_DATA',
            payload: disclosuresBackendUrlToGetData(environment),
        });
        environmentDispatch({
            type: 'SET_SMARTY_URL',
            payload: smartyVerificationUrl(environment),
        });
        environmentDispatch({
            type: 'SET_VCARE_URL',
            payload: vcareApiUrl(environment),
        });
        environmentDispatch({
            type: 'SET_MARKETPLACE_SERVICEABILITY_URL',
            payload: marketplaceServiceabilityBackendUrlToCheck(environment),
        });
        environmentDispatch({
            type: 'SET_KORE_SET_URL',
            payload: backendUrlToSetDataForKore(environment),
        });
        environmentDispatch({
            type: 'SET_FRONTIER_PREDICTIVE_SEARCH_URL',
            payload: { 
                url: urlForFrontierPredictiveSearch(environment),
                key: keyForFrontierPredictiveSearch(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_GET_OFFERS_BY_ADDRESS_URL',
            payload: { 
                url: urlForFrontierGetOffersByAddress(environment),
                key: keyForFrontierGetOffersByAddress(environment),
            },
        });
    }, [environment]);

    return '';
}

export default SetUpEnvironment;
