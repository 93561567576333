// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string,
};
const defaultProps = {
    children: "",
    className: '',
};

function BodyText({ children, className }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/BodyText/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/BodyText/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/BodyText.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/BodyText.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/BodyText/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/BodyText/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return <p className={`${classNames.bodyText} ${className}`}>{children}</p>;
}

BodyText.propTypes = propTypes;
BodyText.defaultProps = defaultProps;

export default BodyText;
