// Packages
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Favicon() {
    const { company } = useParams();

    useEffect(() => {
        // I don't like it, but sadly there is no other way to dynamically render favicon ;(
        const faviconApple = document.getElementById('apple-favicon');
        const favicon32x32 = document.getElementById('32x32-favicon');
        const favicon16x16 = document.getElementById('16x16-favicon');

        faviconApple.href = `/favicons/${company}/apple-touch-icon.png`;
        favicon32x32.href = `/favicons/${company}/favicon-32x32.png`;
        favicon16x16.href = `/favicons/${company}/favicon-16x16.png`;
    }, [company]);
}

export default Favicon;
