import moment from "moment";

export const validateAllFieldsInStep = (step, fields) => {
    let isStepValid = true;
    const fieldsArray = Object.keys(fields);

    for (let i = 0; i < fieldsArray.length; i += 1) {
        if (fields[fieldsArray[i]].step === step && !fields[fieldsArray[i]].isValid) {
            isStepValid = false;
            break;
        }
    }

    return isStepValid;
};

export const runMultipleValidationFunctions = (value, validationFunctions) => {
    let returnValidationObject = { isValid: false, message: '' };

    for (let i = 0; i < validationFunctions.length; i += 1) {
        const tempValidationObject = validationFunctions[i](value);

        returnValidationObject = tempValidationObject;
        if (!tempValidationObject.isValid) {
            break;
        }
    }

    return returnValidationObject;
};

export const requireOnlyValidNLADCharacters = (value) => {
    const regex = /(^[a-zA-ZàèìǹòùẁÀÈÌÒǸÙẀ '-]+$|^$)/u;
    const isValid = regex.test(value);

    if (isValid) {
        return { isValid, message: '' };
    }
    return { isValid, message: "Must contain only letters, spaces, ', -, or accents" };
};

export const requireOnlyNumbers = (value) => {
    const regex = /^\d*$/;
    const isValid = regex.test(value);

    if (isValid) {
        return { isValid, message: '' };
    }
    return { isValid, message: 'Must contain only numbers' };
};

export const requiredCheckbox = (value) => {
    if (value) {
        return { isValid: true, message: '' };
    }
    return { isValid: false, message: 'This field is required' };
};

export const requireNonEmptyValue = (value) => {
    if (value) {
        return { isValid: true, message: '' };
    }
    return { isValid: false, message: 'This field is required' };
};

export const requireDatePicker = (value) => {
    const regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const isValid = regex.test(value);

    if (isValid) {
        return { isValid, message: '' };
    }
    return { isValid, message: 'This field is required' };
};

export const requireXCharacters = (value, length, units = 'digits') => {
    if (value.length === length) {
        return { isValid: true, message: '' };
    }
    return { isValid: false, message: `Must be ${length} ${units} long` };
};
export const requireMoreThanXCharacters = (value, length, units = 'digits') => {
    if (value.length >= length) {
        return { isValid: true, message: '' };
    }
    return { isValid: false, message: `Must be more than ${length} ${units} long` };
};

export const geoCodeValidAddress = (addressObject) => {
    if (
        Object.keys(addressObject.parsedAddress).includes('city') &&
        Object.keys(addressObject.parsedAddress).includes('state') &&
        Object.keys(addressObject.parsedAddress).includes('streetName') &&
        Object.keys(addressObject.parsedAddress).includes('streetNumber') &&
        Object.keys(addressObject.parsedAddress).includes('zip')
    ) {
        return { isValid: true, message: '' };
    }
    return { isValid: false, message: 'Please enter a valid address' };
};

export const requireNotPoBox = (smartyObject) => {
    if (smartyObject?.data?.metadata?.record_type === 'P') {
        return { isValid: false, message: "Can't be a PO Box" };
    }
    return { isValid: true, message: '' };
};
export const requireValidAddress = (smartyObject) => {
    if (!smartyObject?.data?.metadata?.record_type) {
        return { isValid: false, message: 'Please enter a valid address' };
    }
    return { isValid: true, message: '' };
};
export const requireResidentialAddress = (smartyObject) => {
    if (smartyObject?.data?.metadata?.rdi !== 'Residential') {
        return { isValid: false, message: 'Must be a Residential Address' };
    }
    return { isValid: true, message: '' };
};
export const requireAddressLine2IfAddressHasUnits = (smartyObject) => {
    if (smartyObject?.data?.analysis?.enhanced_match?.includes('missing-secondary')) {
        return { isValid: false, message: 'Missing suite, apartment or lot number' };
    }
    return { isValid: true, message: '' };
};
export const requireValidAddressLine2 = (smartyObject) => {
    if (
        smartyObject?.data?.analysis?.enhanced_match?.includes('unknown-secondary') || smartyObject?.data?.components?.secondary_designator === "#" ||
        (smartyObject?.data?.metadata?.record_type === 'H' &&
            !smartyObject?.data?.components?.secondary_designator &&
            !smartyObject?.data?.components?.secondary_number)
    ) {
        return { isValid: false, message: 'Please enter a valid suite, apartment or lot number (leave blank if none)' };
    }
    return { isValid: true, message: '' };
};

export const requireAgeToBeGreaterThan18 = (value) => {
    const currentDate = moment()
    const valueMoment = moment(value)

    const differenceInYears = currentDate.diff(valueMoment, 'years')

    if (differenceInYears < 18) {
        return { isValid: false, message: 'Must be 18 years or older' }
    }
    return { isValid: true, message: '' }
}

export const requireNotToBeAllSpaces = (value) => {
    if (value && !value.replace(/\s/g, '').length) {
        return { isValid: false, message: 'Must not contain only spaces' }
    }
    return { isValid: true, message: '' }
}

export default runMultipleValidationFunctions;
