// Packages
import { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Contexts
import { StepsContext } from '../contexts/Steps';
import { FormDataContext } from '../contexts/FormData';
import { EnvironmentContext } from '../contexts/Environment';
import { ValidationContext } from '../contexts/Validation';
import { IsLoadingContext } from '../contexts/IsLoading';

// Props of the Component
const propTypes = {
    page: PropTypes.string.isRequired,
};

function SetUpContexts({ page }) {
    const { userId, company } = useParams();
    const [filledFormData, setFilledFormData] = useState(false);
    const [stepsState, stepsDispatch] = useContext(StepsContext);
    const [formDataState, formDataDispatch] = useContext(FormDataContext);
    const [environmentState] = useContext(EnvironmentContext);
    const [validationState, validationDispatch] = useContext(ValidationContext);
    const isLoadingDispatch = useContext(IsLoadingContext)[1];

    useEffect(() => {
        isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpContextsValidaton" })
        isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpContextsSteps" })
        isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpContextsFormData" })
    }, [])

    useEffect(() => {
        if (Object.keys(validationState.fields).length > 0) {
            isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsValidaton" })
        }
    }, [validationState]);
    useEffect(() => {
        if (stepsState.steps.length > 0) {
            isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsSteps" })
        }
    }, [stepsState]);
    useEffect(() => {
        if (Object.keys(formDataState).length > 0) {
            if ((page === 'edit' || page === 'form' || page === 'disclosures') && !filledFormData && company !== 'redpocketai') {
                const headers = {
                    Authorization: company,
                    "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                }
                const body = {
                    userId: userId,
                };
                fetch(environmentState.backendUrlToGetData, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        const tempFormData = { ...formDataState };

                        const fieldMapping = {
                            "mc_firstName": "firstName",
                            "mc_lastName": "lastName",
                            "display_dob": "dob",
                            "mc_ssn": "ssn",
                            "tempAddress": "temporaryAddress",
                            "mc_phone": "phoneNumber",
                            "mc_address": "address",
                            "mc_addressLn2": "addressline2",
                            "mc_city": "addresscity",
                            "mc_middleName": "middleName",
                            "mc_secondLastName": "secondLastName",
                            "mc_shippingAddress": "shippingAddress",
                            "mc_shippingAddressLn2": "shippingAddressline2",
                            "mc_shippingCity": "shippingAddresscity",
                            "mc_shippingState": "shippingAddressstate",
                            "mc_shippingZipCode": "shippingAddresszip",
                            "mc_state": "addressstate",
                            "mc_suffix": "suffix",
                            "mc_tribal_bool": "isTribal",
                            "mc_zipCode": "addresszip",
                            "enrollment_type": "enrollment_type",
                            "clicked_activate_demo": "clickedActivateDemo",
                            "serviceableCompany": "serviceableCompany",
                            "is_existing_customer": "existingCustomer",
                            "freeform_address_times_at_page_1": "freeformAddressTimesAtPage1",
                            "freeform_address_times_at_page_2": "freeformAddressTimesAtPage2",
                            "freeform_address_times_at_page_3": "freeformAddressTimesAtPage3",
                            "optimum_skip": "optimum_skip",
                            "from_widget": "fromWidget",
                            "move_to_next_company": "moveToNextCompany",
                            "serviceable_companies": "serviceableCompanies"
                        }

                        let addressObject = {}
                        let shippingAddressObject = {}

                        data.data.custom_fields.forEach((field) => {
                            const mappedField = fieldMapping[field.name]

                            if (mappedField) {
                                if (mappedField.startsWith("address")) {
                                    let parsedAddressKey = mappedField.split("address")[1]
                                    if (!parsedAddressKey) {
                                        parsedAddressKey = "street"
                                    }
                                    addressObject = { parsedAddress: {...addressObject?.parsedAddress, [parsedAddressKey]: field.value } }
                                } else if (mappedField.startsWith("shippingAddress")) {
                                    let parsedAddressKey = mappedField.split("shippingAddress")[1]
                                    if (!parsedAddressKey) {
                                        parsedAddressKey = "street"
                                    }
                                    shippingAddressObject = { parsedAddress: {...shippingAddressObject?.parsedAddress, [parsedAddressKey]: field.value } }
                                } else {
                                    if (mappedField === "phoneNumber" && field.value.startsWith("+1")) {
                                        tempFormData[mappedField] = field.value.slice(2);
                                    } else {
                                        tempFormData[mappedField] = field.value;
                                    }
                                }
                            }
                        });

                        if (!addressObject?.parsedAddress?.street && addressObject?.parsedAddress?.zip) {
                            addressObject = { preferedZip: addressObject.parsedAddress.zip }
                        } else {
                            addressObject = { ...addressObject, preferedZip: addressObject.parsedAddress.zip }
                        }
                        if (!shippingAddressObject?.parsedAddress?.street && shippingAddressObject?.parsedAddress?.zip) {
                            shippingAddressObject = { preferedZip: shippingAddressObject.parsedAddress.zip }
                        } else if (shippingAddressObject?.parsedAddress?.street && shippingAddressObject?.parsedAddress?.zip) {
                            shippingAddressObject = { ...shippingAddressObject, preferedZip: addressObject.parsedAddress.zip }
                        } else {
                            shippingAddressObject = null
                        }

                        tempFormData.address = addressObject
                        tempFormData.shippingAddress = shippingAddressObject

                        formDataDispatch({ type: 'SET', payload: tempFormData });
                        setFilledFormData(true);
                        isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsFormData" })
                    })
                    .catch((error) => {
                        console.error(error);
                        isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsFormData" })
                        setFilledFormData(true);
                    });
            } else {
                isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsFormData" })
            }
        }
    }, [formDataState, filledFormData]);

    useEffect(() => {
        const asyncFunction = async () => {
            import(/* webpackPreload: true */ `../companies/${company}/contexts/${page}/steps.jsx`)
                .then(async (module) => {
                    const companySteps = await module.default(company)
                    if (Array.isArray(companySteps)) {
                        stepsDispatch({ type: 'SET_STEPS', payload: companySteps });
                    } else {
                        stepsDispatch({ type: 'SET_STEPS_AND_METADATA', payload: companySteps });
                    }
                })
                .catch(() => {
                    import(/* webpackPreload: true */ `../companies/default/contexts/${page}/steps.jsx`).then(async (module) => {
                        stepsDispatch({ type: 'SET_STEPS', payload: await module.default(company) });
                    });
                });
            import(/* webpackPreload: true */ `../companies/${company}/contexts/${page}/formData.js`)
                .then((module) => {
                    formDataDispatch({ type: 'SET', payload: module.default });
                })
                .catch(() => {
                    import(/* webpackPreload: true */ `../companies/default/contexts/${page}/formData.js`).then((module) => {
                        formDataDispatch({ type: 'SET', payload: module.default });
                    });
                });
            import(/* webpackPreload: true */ `../companies/${company}/contexts/${page}/validation.js`)
                .then((module) => {
                    validationDispatch({ type: 'SET_FIELDS', payload: module.default });
                })
                .catch(() => {
                    import(/* webpackPreload: true */ `../companies/default/contexts/${page}/validation.js`).then(
                        (module) => {
                            validationDispatch({ type: 'SET_FIELDS', payload: module.default });
                        }
                    );
                });
        }
        
        asyncFunction()
    }, [company, page]);

    return '';
}

SetUpContexts.propTypes = propTypes;

export default SetUpContexts;
