const initialState = {
    backendUrlToGetData: '',
    backendUrlToSetData: '',
    backendUrlToSubmitData: '',
    disclosuresBackendUrlToGetData: '',
    smartyUrl: '',
    vcareUrl: '',
    marketplaceServiceabilityBackendUrlToCheck: '',
    backendUrlToSetDataForKore: '',
    frontierPredictiveSearchUrl: '',
    frontierGetOffersByAddressUrl: ''
};

export default initialState;
