// Packages
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Contexts
import { StepsContext } from '../contexts/Steps';

// Props of the Component
const propTypes = {
};

const ProgressBar = ({ }) => {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [imageObject, setImageObject] = useState({});
    const [asyncStepLengthChange, setAsyncStepLengthChange] = useState({});
    const [percentageList, setPercentageList] = useState(null);
    const [currentProgress, setCurrentProgress] = useState(null);
    const [progressLength, setProgressLength] = useState(null);
    const [staticProgressPercent, setStaticProgressPercent] = useState(0);
    const [amountOfAsyncStepsWaiting, setAmountOfAsyncStepsWaiting] = useState(0);
    const [totalPercentRemaining, setTotalPercentRemaining] = useState(100);
    const [subtractFromCurrentStep, setSubtractFromCurrentStep] = useState(0);
    const [subtractFromLength, setSubtractFromLength] = useState(0);
    const [lastStepCalculated, setLastStepCalculated] = useState(null);
    const [steps, stepsDispatch] = useContext(StepsContext);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ProgressBar/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ProgressBar/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/ProgressBar.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/ProgressBar.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ProgressBar/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ProgressBar/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ProgressBar/imageObjects.js`)
            .then((module) => {
                setImageObject(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ProgressBar/imageObjects.js`).then(
                    (module) => {
                        setImageObject(module.default);
                    }
                );
            });
    }, [company]);


    useEffect(() => {
        if (lastStepCalculated < steps.currentStep || lastStepCalculated === null) {
            if (steps.steps.length > progressLength) {
                let continueToAdding = true
    
                if (steps.metaData?.asyncStep) {
                    const arrayOfAsyncSteps = Object.keys(steps.metaData.asyncStep)
                    if (arrayOfAsyncSteps.length !== amountOfAsyncStepsWaiting) {
                        continueToAdding = false
                        const ifSyncStepsHasKeys = arrayOfAsyncSteps.some((element) => steps.metaData.asyncStep[element])
                        if (ifSyncStepsHasKeys) {
                            let tempObject = { ...asyncStepLengthChange }
            
                            for (let i = 0; i < arrayOfAsyncSteps.length; i += 1) {
                                let previousIndexArray = tempObject[steps.metaData.asyncStep[arrayOfAsyncSteps[i]]] ? tempObject[steps.metaData.asyncStep[arrayOfAsyncSteps[i]]] : []
                                tempObject[steps.metaData.asyncStep[arrayOfAsyncSteps[i]]] = [...previousIndexArray, arrayOfAsyncSteps[i]]
                            }
            
                            setAsyncStepLengthChange(tempObject)
                            setAmountOfAsyncStepsWaiting(prev => prev + 1)
                        }
                    }
                } 
            }
    
            if (asyncStepLengthChange[steps.currentStep]) {
                const tempStaticProgress = percentageList[steps.currentStep - 1]
                const tempCurrentProgress = 1
                const tempProgressLength = ((progressLength - (subtractFromLength - 1)) - (currentProgress - subtractFromCurrentStep)) + 1
                const tempPercentRemaining = totalPercentRemaining - percentageList[steps.currentStep - 1]
    
                setPercentageList((prev) => { return { 
                    ...prev, [steps.currentStep]: 
                        tempStaticProgress + ((tempCurrentProgress / tempProgressLength) * tempPercentRemaining)
                }})
    
                setStaticProgressPercent(tempStaticProgress)
                setCurrentProgress(tempCurrentProgress + 1)
                setProgressLength(tempProgressLength)
                setSubtractFromCurrentStep(0)
                setSubtractFromLength(0)
                setTotalPercentRemaining(tempPercentRemaining)
            } else if (percentageList === null) {
                setPercentageList({ [steps.currentStep]: 0 })
                setCurrentProgress(prev => prev + 1)
                setProgressLength(steps.steps.length)
                setSubtractFromLength(steps.metaData?.subtractFromStepsLength ? steps.metaData?.subtractFromStepsLength + 1 : 1)
            } else if (!steps.steps[steps.currentStep]?.dontMoveProgressBar) {
                setPercentageList((prev) => { return { 
                    ...prev, [steps.currentStep]: 
                        staticProgressPercent + 
                        (((currentProgress - subtractFromCurrentStep) / 
                        (progressLength - subtractFromLength)) 
                        * totalPercentRemaining)
                }})
    
                setCurrentProgress(prev => prev + 1)
            } else if (steps.steps[steps.currentStep]?.dontMoveProgressBar) {
                setPercentageList((prev) => { return { ...prev, [steps.currentStep]: prev[steps.currentStep - 1] } })
                setSubtractFromCurrentStep(prev => prev + 1)
                setCurrentProgress(prev => prev + 1)
            }
        } else {
            setCurrentProgress(prev => prev - 1)
        }

        setLastStepCalculated(steps.currentStep)
    }, [steps.currentStep])

    return (
        <div className={classNames.container}>
            <img 
                className={classNames.progressIndicator} 
                src={imageObject?.progressBarIndicator?.src} 
                alt={imageObject?.progressBarIndicator?.altText} 
                style={{ 
                    left: `${typeof percentageList === 'object' && percentageList !== null ? 
                        (percentageList[steps.currentStep] ? percentageList[steps.currentStep] : 0) : 
                        0
                    }%`
                }}
            />
            <div className={classNames.innerBar} style={{ width: `${typeof percentageList === 'object' && percentageList !== null ? 
                    (percentageList[steps.currentStep] ? percentageList[steps.currentStep] : 0) : 
                    0
                }%` }}
            />
        </div>
    );
};

ProgressBar.propTypes = propTypes;

export default ProgressBar;
