// Packages
import React from 'react'
// Helpers
import initialState from './initialState';

const PopupReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_POPUP':
            return { 
                display: true,
                title: action.payload.title,
                onClick: action.payload.onClick,
                componentToRender: action.payload.component
            };
        case 'REMOVE_POPUP':
            return {     
                display: false,
                componentToRender: <></> 
            };
        case 'CLEAR_POPUP':
            return initialState;
        default:
            return state;
    }
};

export default PopupReducer;
