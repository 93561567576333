// Packages
import React from 'react';
// Components
import Routers from './components/Routers';
// CSS
import 'normalize.css';

function App() {
    return <Routers />;
}

export default App;
