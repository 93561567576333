const StepsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_STEPS':
            return { ...state, steps: action.payload };
        case 'SET_STEPS_AND_METADATA':
            return { ...state, metaData: action.payload.metaData, steps: action.payload.steps };
        case 'APPEND_STEP':
            return { ...state, steps: [...state.steps, action.payload]};
        case 'REMOVE_STEP':
            let tempSteps = [ ...state.steps ]
            tempSteps.splice(action.payload, 1)
            return { ...state, steps: tempSteps};
        case 'SET_CURRENT_STEP':
            return { ...state, currentStep: action.payload };
        case 'INCREMENT_STEP':
            return { ...state, currentStep: state.currentStep + 1 };
        case 'DECREMENT_STEP':
            return { ...state, currentStep: state.currentStep - 1 };
        case 'INCREMENT_METADATA_SUBTRACTFROMSTEPSLENGTH':
            return { ...state, metaData: { ...state.metaData, subtractFromStepsLength: state.metaData.subtractFromStepsLength + 1 } };
        case 'ADD_ASYNC_STEP_ADD': 
            return { ...state, metaData: { ...state.metaData, asyncStep: { ...state.metaData.asyncStep, [action.payload.name]: action.payload.indexOccursOn } } };
        default:
            return state;
    }
};

export default StepsReducer;
